import * as _ from "lodash";
import { useParams } from "react-router";
import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { CalendarMonthOutlined, Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Typography,
  Select as Dropdown,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import moment from "moment";
import { Line } from "react-chartjs-2";
import Select, { MultiValue } from "react-select";
import { Option } from "../../constants/interfaces";
import { DatePicker, DisplayCharts } from "../../components";
import { WindDirectionCharts } from "../../components";
import { Mappings, WindDirectionMapping } from "../../constants/WordMapping";
import { getHistoricalSensorData } from "../../services/plot.service";
import { getDate, print } from "../../utils/helper";
import ZoomPlugin from "chartjs-plugin-zoom";
import {
  getHistoricalDiseasePrediction,
  getHistoricalPestPrediction,
  getWindDirectionPrediction,
} from "../../services/plot.service";
import { saveToXlsxHistorical } from "../../utils/XLSX";
import dayjs from "dayjs";
import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as XLSX from "xlsx";
import { AgGridComponent } from "src/pages/Inventory";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import GridOnIcon from "@mui/icons-material/GridOn";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ZoomPlugin
);

interface PlotID {
  propsPlotId?: string;
}

const HistoricalData: React.FC<PlotID> = (props): JSX.Element => {
  const { propsPlotId } = props;
  let { plotId } = useParams();
  if (propsPlotId !== undefined) {
    plotId = propsPlotId;
  }

  /* Dates
    toDate and fromDate -> states date range of charts
    modalToDate, modalFromDate -> states date range of csv data
  */
  const [toDate, setToDate] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [modalFromDate, setModalFromDate] = useState<Date | null>(null);
  const [modalToDate, setModalToDate] = useState<Date | null>(null);
  const [isChartView, setIsChartView] = useState<boolean>(true);

  /* data fields -> in naam ke charts banenge 
      - string[]: ['field1', 'field2', ...]
      all fields are without mapping
  */
  const [dataFields, setDataFields] = useState<string[]>([]); // for device data i.e soiltemp, etc
  const [diseaseChartsFields, setDiseaseChartsFields] = useState<string[]>([]); // for diseases
  const [pestChartsFields, setPestChartsFields] = useState<string[]>([]); // for pests

  /* charts mein ye data display hoga 
      - any[]:  [
                  {
                    timestamp: '',
                    field1: 23,
                    field2: 12,
                    ...
                  }
                ]
  */
  const [historicalData, setHistoricalData] = useState<any>(); // historicalData.data is any[]
  const [diseaseChartsData, setDiseaseChartsData] = useState<any[]>([]);
  const [pestChartsData, setPestChartsData] = useState<any[]>([]);
  const [windChartsData, setWindChartsData] = useState<any[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  // dropdown options: all fields of historical data
  const AverageDataFieldOptions: Option[] = [
    {
      label: Mappings.soilMoisture1,
      value: "soilMoisture1",
    },
    {
      label: Mappings.soilMoisture2,
      value: "soilMoisture2",
    },
    {
      label: Mappings.airTemp,
      value: "airTemp",
    },
    {
      label: Mappings.airHumidity,
      value: "airHumidity",
    },
    {
      label: Mappings.rainFall,
      value: "rainFall",
    },
    {
      label: Mappings.lightIntensity,
      value: "lightIntensity",
    },
    {
      label: Mappings.windSpeed,
      value: "windSpeed",
    },
    {
      label: Mappings.airPressure,
      value: "airPressure",
    },
    {
      label: Mappings.soilTemp,
      value: "soilTemp",
    },
    {
      label: Mappings.leafWetness,
      value: "leafWetness",
    },
    {
      label: Mappings.battery,
      value: "battery",
    },
    {
      label: Mappings.signalStrength,
      value: "signalStrength",
    },
  ];

  const MinDataFieldOptions: Option[] = [
    {
      label: Mappings.MinSoilMoisture1,
      value: "MinSoilMoisture1",
    },
    {
      label: Mappings.MinSoilMoisture2,
      value: "MinSoilMoisture2",
    },
    {
      label: Mappings.MinAirTemp,
      value: "MinAirTemp",
    },
    {
      label: Mappings.MinAirHumidity,
      value: "MinAirHumidity",
    },
    {
      label: Mappings.rainFall,
      value: "rainFall",
    },
    {
      label: Mappings.MinLux,
      value: "MinLux",
    },
    {
      label: Mappings.MinWindSpeed,
      value: "MinWindSpeed",
    },
    {
      label: Mappings.MinAirPressure,
      value: "MinAirPressure",
    },
    {
      label: Mappings.MinSoilTemp,
      value: "MinSoilTemp",
    },
    {
      label: Mappings.MinLeafWet,
      value: "MinLeafWet",
    },
    {
      label: Mappings.MinBattery,
      value: "MinBattery",
    },
    {
      label: Mappings.MinSignalStrength,
      value: "MinSignalStrength",
    },
  ];

  const MaxDataFieldOptions: Option[] = [
    {
      label: Mappings.MaxSoilMoisture1,
      value: "MaxSoilMoisture1",
    },
    {
      label: Mappings.MaxSoilMoisture2,
      value: "MaxSoilMoisture2",
    },
    {
      label: Mappings.MaxAirTemp,
      value: "MaxAirTemp",
    },
    {
      label: Mappings.MaxAirHumidity,
      value: "MaxAirHumidity",
    },
    {
      label: Mappings.rainFall,
      value: "rainFall",
    },
    {
      label: Mappings.MaxLux,
      value: "MaxLux",
    },
    {
      label: Mappings.MaxWindSpeed,
      value: "MaxWindSpeed",
    },
    {
      label: Mappings.MaxAirPressure,
      value: "MaxAirPressure",
    },
    {
      label: Mappings.MaxSoilTemp,
      value: "MaxSoilTemp",
    },
    {
      label: Mappings.MaxLeafWet,
      value: "MaxLeafWet",
    },
    {
      label: Mappings.MaxBattery,
      value: "MaxBattery",
    },
    {
      label: Mappings.MaxSignalStrength,
      value: "MaxSignalStrength",
    },
  ];

  const [chartData, setChartData] = useState<Option[]>(AverageDataFieldOptions);
  const [chartDataType, setChartDataType] = useState<string>("avg");

  // dropdown options: historical data + disease + pest
  const [allChartsDataFieldOptions, setallChartsDataFieldOptions] =
    useState<Option[]>(chartData);

  /* 

  */
  const [openModal, setOpenModal] = useState<boolean>(false); // if true: modal is opened
  const [isDownloadModal, setIsDownloadModal] = useState<boolean>(false); // states: to download csv
  const [showMergedCharts, setShowMergedCharts] = useState<boolean>(false); // states: display merged charts or all charts
  const [selectedFieldsToMerge, setSelectedFieldsToMerge] = useState<
    MultiValue<any>
  >([]);
  const [daysCount, setDaysCount] = useState<number>(7);
  const [chartTypeDaily, setChartTypeDaily] = useState<boolean>(false);

  const handleModalClose = () => {
    setOpenModal(false);
    setIsDownloadModal(false);
  };

  const handleFromDate = (days = 7) => {
    setDaysCount(days);

    const newPrevDate = new Date(
      toDate.getFullYear() - (days === 365 ? 1 : 0),
      toDate.getMonth() - (days === 30 ? 1 : 0),
      toDate.getDate() - (days === 1 ? 1 : days === 7 ? 7 : 0)
    );
    setFromDate(newPrevDate);
    setToDate(new Date());
  };

  useEffect(() => {
    handleFromDate();
  }, []);

  // converts disease and pest data to format reqd for chart
  const getConvertedData = (data: any[], isDisease = true) => {
    let result: any[] = [];
    data?.map((disease) => {
      for (let ds of disease.data) {
        const { calculatedDate, probability, diseaseId, pestId, riskLevel } =
          ds;
        const timestamp = moment(calculatedDate).format("LL");

        let flag = 0;
        result?.map((diseaseData) => {
          if (diseaseData.timestamp === timestamp) {
            diseaseData[isDisease ? diseaseId : pestId] = probability;
            diseaseData[
              isDisease ? diseaseId + "_riskLevel" : pestId + "_riskLevel"
            ] = riskLevel;
            flag = 1;
          }
        });

        if (!flag) {
          let x: any = { timestamp };
          x[isDisease ? diseaseId : pestId] = probability;
          x[isDisease ? diseaseId + "_riskLevel" : pestId + "_riskLevel"] =
            riskLevel;
          result.push(x);
        }
      }
    });
    return result;
  };

  // fetches chart data on every plotId and Date changes
  useEffect(() => {
    let newFromDate = moment(moment(fromDate).toDate().setHours(0,0,0,0)).toDate();
    const newToDate = moment(toDate).endOf("day").toDate();
    setModalFromDate(newFromDate);
    setModalToDate(newToDate);

    getWindDirectionPrediction(plotId!, newFromDate, newToDate).then((res) => {
      let finalData = res;
      finalData?.map((val: any) => {
        val.windDirection = WindDirectionMapping[val.windDirection];
        val.windSpeed = 10;
      });
      setWindChartsData(finalData);
    });

    fetchDeviceData(plotId!, moment(newFromDate).toISOString(), moment(newToDate).toISOString());

    getHistoricalDiseasePrediction(plotId!, newFromDate, newToDate).then(
      (res) => {
        const tempDiseaseFields = res?.map((disease: any) => disease.id);
        const convertedData = getConvertedData(res);
        setDiseaseChartsFields(tempDiseaseFields);
        setDiseaseChartsData(convertedData);
      }
    );

    getHistoricalPestPrediction(plotId!, newFromDate, newToDate).then((res) => {
      const tempPestFields = res.map((pest: any) => pest.id);
      const convertedData = getConvertedData(res, false);
      setPestChartsFields(tempPestFields);
      setPestChartsData(convertedData);
    });
  }, [plotId, fromDate, toDate, isChartView, chartTypeDaily, chartData]);

  useEffect(() => {
    setChartData(
      chartDataType === "avg"
        ? AverageDataFieldOptions
        : chartDataType === "min"
        ? MinDataFieldOptions
        : MaxDataFieldOptions
    );
  }, [chartDataType]);

  const fetchDeviceData = async (
    plotId: string,
    newFromDate: string,
    newToDate: string
  ) => {
    let res;

    if (chartTypeDaily) {
      res = await getHistoricalSensorData(
        plotId,
        newFromDate,
        newToDate,
        "daily"
      );
    } else {
      res = await getHistoricalSensorData(
        plotId,
        newFromDate,
        newToDate,
        "hourly"
      );
    }

    setHistoricalData(res);
    const tempDataFields = chartData?.map((field) => field.value as string);
    setDataFields(tempDataFields);
  };

  // append fields to allFieldOptions on diseaseChartsFields & pestChartsFields change
  useEffect(() => {
    if (pestChartsFields.length > 0) {
      appendToAllFieldOptions(pestChartsFields);
    }
  }, [pestChartsFields]);

  useEffect(() => {
    if (diseaseChartsFields.length > 0) {
      appendToAllFieldOptions(diseaseChartsFields);
    }
  }, [diseaseChartsFields]);

  useEffect(() => {
    // console.log('all charts data fields', allChartsDataFieldOptions)
  }, [allChartsDataFieldOptions]);

  const getCommonLabels = (combinedLabels: string[], labels: string[]) => {
    if (combinedLabels.length > 0) {
      return _.intersection(combinedLabels, labels);
    }
    return labels;
  };

  // Merge Chart: generates Chart data for selected options from dropdown
  const generateGraphData = (field: MultiValue<any>) => {
    const timeFormat = daysCount !== 1 ? "lll" : "MMMM Do YYYY, h A";
    const labels: string[] = historicalData?.data?.map((data: any) =>
      getDate(data.timestamp, timeFormat)
    );
    const diseaseLabels = diseaseChartsData.map((data: any) =>
      getDate(data.timestamp, timeFormat)
    );
    const pestLabels = pestChartsData.map((data: any) =>
      getDate(data.timestamp, timeFormat)
    );

    const colors = [
      "green",
      "blue",
      "orange",
      "purple",
      "violet",
      "dodgerblue",
      "lightGray",
      "MediumSeaGreen",
      "tomato",
      "cyan",
    ];
    const totalColors = colors.length;

    let stylee: any = [];
    const downArrow = new Image();
    downArrow.src = require("../../assets/down.png");
    downArrow.width = 40;
    downArrow.height = 40;
    const upArrow = new Image();
    upArrow.src = require("../../assets/up.png");
    upArrow.width = 40;
    upArrow.height = 40;
    const westArrow = new Image();
    westArrow.src = require("../../assets/left.png");
    westArrow.width = 40;
    westArrow.height = 40;
    const eastArrow = new Image();
    eastArrow.src = require("../../assets/right.png");
    eastArrow.width = 40;
    eastArrow.height = 40;

    let hasDisease = false;
    let hasPest = false;
    let hasSensors = false;

    const sensorField = AverageDataFieldOptions.concat(MinDataFieldOptions)
      .concat(MaxDataFieldOptions)
      ?.map((field) => field.value as string);

    for (const fld of field) {
      const { value } = fld;
      hasDisease = hasDisease || diseaseChartsFields.includes(value);
      hasPest = hasPest || pestChartsFields.includes(value);
      hasSensors = hasSensors || sensorField.includes(value);
    }

    // jitne labels, utne values
    let combinedLabels: string[] = [];
    if (hasDisease) {
      combinedLabels = getCommonLabels(combinedLabels, diseaseLabels);
    }

    if (hasPest) {
      combinedLabels = getCommonLabels(combinedLabels, pestLabels);
    }

    if (hasSensors) {
      combinedLabels = getCommonLabels(combinedLabels, labels);
    }

    const datasets = field?.map((fld, ind = 0) => {
      const { value } = fld;
      if (value === "windSpeed") {
        windChartsData?.map((val) => {
          if (val.windDirection === "N") stylee.push(upArrow);
          else if (val.windDirection === "S") stylee.push(downArrow);
          else if (val.windDirection === "E") stylee.push(eastArrow);
          else if (val.windDirection === "W") stylee.push(westArrow);
        });
      }
      const data = generateDataOfDataset(value, combinedLabels, timeFormat);
      return {
        label: Mappings[value] ?? value,
        data: data,
        borderColor: colors[ind % totalColors],
        backgroundColor: colors[ind % totalColors],
        borderWidth: value === "windSpeed" ? 1 : 3,
        pointBackgroundColor:
          value === "windSpeed" ? "transparent" : colors[ind % totalColors],
        pointStyle: value === "windSpeed" ? stylee : [],
      };
    });

    return {
      labels: combinedLabels,
      datasets: datasets,
    };
  };

  const generateDataOfDataset = (
    key: string,
    labels: string[],
    timeFormat = "lll"
  ) => {
    const result: any = [];
    const noData = undefined;
    // const noData = '' // use this if want to join all dots (jahan pe data nahi rahega vo 0 ho jaaega)
    for (const time of labels) {
      let flag = 0; // check if data contains object having this time

      // identify -> 'key' kiska hai
      if (diseaseChartsFields.includes(key)) {
        for (const data of diseaseChartsData) {
          if (getDate(data.timestamp, timeFormat) === time) {
            result.push(data[key]);
            flag = 1;
          }
        }
      } else if (pestChartsFields.includes(key)) {
        for (const data of pestChartsData) {
          if (getDate(data.timestamp, timeFormat) === time) {
            result.push(data[key]);
            flag = 1;
          }
        }
      } else {
        for (const data of historicalData?.data) {
          if (getDate(data.timestamp, timeFormat) === time) {
            result.push(data[key]);
            flag = 1;
          }
        }
      }

      if (!flag) {
        result.push(noData);
      }
    }

    return result;
  };

  const loadModalDate = () => {
    if (modalFromDate) setFromDate(modalFromDate);
    if (modalToDate) setToDate(modalToDate);
    handleModalClose();
  };

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: (
      <IconButton>
        <CalendarMonthOutlined />
      </IconButton>
    ),
    clearIcon: <Clear />,
  };

  const handleFieldSelect = (selected: MultiValue<any>) => {
    setSelectedFieldsToMerge(selected);
  };

  const handleDownloadChartData = () => {
    setOpenModal(true);
    setIsDownloadModal(true);
  };

  const downloadData = (data: any[]) => {
    const columns = [
      "Date",
      "Signal Strength(%)",
      "Battery(%)",
      "Air Temperature(℃)",
      "Air Humidity(%)",
      "Air Pressure(kPa)",
      "Soil Temperature(℃)",
      "Soil Moisture 1(kPa)",
      "Soil Moisture 2(kPa)",
      "Light Intensity(lux)",
      "Leaf Wetness Hours",
      "Wind Speed(km/h)",
      "Rain Fall(mm)",
      "Leaf Wetness",
      "Baro Pressure",
      "Max Battery(%)",
      "Max Air Humidity(%)",
      "Max Air Pressure(kPa)",
      "MaxAirTemp(℃)",
      "Max Baro Pressure",
      "Max Leaf Wet",
      "Max Lux",
      "Max Signal Strength(%)",
      "Max Soil Moisture 1(kPa)",
      "Max Soil Moisture 2(kPa)",
      "Max Soil Temp(℃)",
      "Max Wind Speed(km/h)",
      "Min Air Humidity(%)",
      "Min Air Pressure(kPa)",
      "Min Air Temp(℃)",
      "Min Baro Pressure",
      "Min Battery(%)",
      "Min Leaf Wet",
      "Min Lux",
      "Min Signal Strength(%)",
      "Min Soil Moisture 1(kPa)",
      "Min Soil Moisture 2(kPa)",
      "Min Soil Temp(℃)",
      "Min Wind Speed(km/h)",
      "Day Length",
      "Delta Soil Moisture 1",
      "Delta Soil Moisture 2",
      "Sunshine Hours",
    ];

    const rowData = data?.map((dt) => [
      dt.timestamp,
      dt.signalStrength,
      dt.battery,
      dt.airTemp,
      dt.airHumidity,
      dt.airPressure,
      dt.soilTemp,
      dt.soilMoisture1,
      dt.soilMoisture2,
      dt.lightIntensity,
      dt.leafWetnessHours,
      dt.windSpeed,
      dt.rainFall,
      dt.leafWetness,
      dt.baroPressure,
      dt.MaxBattery,
      dt.MaxAirHumidity,
      dt.MaxAirPressure,
      dt.MaxAirTemp,
      dt.MaxBaroPressure,
      dt.MaxLeafWet,
      dt.MaxLux,
      dt.MaxSignalStrength,
      dt.MaxSoilMoisture1,
      dt.MaxSoilMoisture2,
      dt.MaxSoilTemp,
      dt.MaxWindSpeed,
      dt.MinAirHumidity,
      dt.MinAirPressure,
      dt.MinAirTemp,
      dt.MinBaroPressure,
      dt.MinBattery,
      dt.MinLeafWet,
      dt.MinLux,
      dt.MinSignalStrength,
      dt.MinSoilMoisture1,
      dt.MinSoilMoisture2,
      dt.MinSoilTemp,
      dt.MinWindSpeed,
      dt.dayLength,
      dt.deltaSoilMoisture1,
      dt.deltaSoilMoisture2,
      dt.sunshineHours,
    ]);

    const finalData = [columns, ...rowData];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(finalData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const fileName = `${plotId}-${fromDate}-${toDate}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  const downloadChartData = () => {
    getHistoricalSensorData(
      plotId!,
      moment(moment(modalFromDate).toDate().setHours(0,0,0,0)).toDate()!.toISOString(),
      moment(modalToDate).endOf("day").toDate().toISOString()!,
      !!chartTypeDaily ? "daily" : "hourly"
    ).then((res: any) => {
      saveToXlsxHistorical(res, `${plotId}-${moment(modalFromDate).format("DD/MMM/YYYY")}-${moment(modalToDate).format("DD/MMM/YYYY")}`, chartTypeDaily);

      setOpenModal(false);
      setIsDownloadModal(false);
    });
  };

  const appendToAllFieldOptions = (newOptions: string[]) => {
    const tempNewOptions = newOptions?.map((option: string) => {
      return {
        label: Mappings[option],
        value: option,
      };
    });

    const tempAllOptions = [...allChartsDataFieldOptions, ...tempNewOptions];
    const allUniqueOptions = tempAllOptions.filter(
      (item, ind, ar) => ar.indexOf(item) === ind
    );
    setallChartsDataFieldOptions(allUniqueOptions);
  };

  const filteredOptions = useMemo(() => {
    if (!historicalData?.data?.[0]) return [];
    return Object.keys(historicalData?.data?.[0])
      ?.filter((k) => k && Mappings?.hasOwnProperty(k))
      ?.map((k) => ({ label: Mappings[k], value: k }));
  }, [historicalData]);

  let defaultCoulumn: string[] = [
    "timestamp",
    "soilMoisture1",
    "soilMoisture2",
    "airTemp",
    "airHumidity",
    "rainFall",
    "lightIntensity",
    "windSpeed",
    "airPressure",
    "soilTemp",
    "leafWetness",
    "battery",
    "signalStrength",
  ];

  useEffect(() => {
    setSelectedOptions(
      filteredOptions?.filter((option) =>
        defaultCoulumn?.includes(option.value)
      )
    );
  }, [filteredOptions]);

  const sensorDef = useMemo(() => {
    return selectedOptions?.map((option: any) => ({
      headerName: option.label,
      field: option.value,
      autoHeight: true,
      sortable: true,
      filter: "agTextColumnFilter",
      minWidth: 230,
      valueFormatter: (params: any) => {
        if (option.value === "timestamp") {
          return dayjs(params.value).format("LLL");
        }
        return params.value;
      },
    }));
  }, [selectedOptions]);

  const diseasePestDef = (type: string) => {
    const def: any[] = [
      {
        headerName: "Date",
        field: "timestamp",
        autoHeight: true,
        sortable: true,
        filter: "agTextColumnFilter",
        minWidth: 230,
        valueFormatter: (params: any) => {
          return dayjs(params.value).format("LLL");
        },
      },
    ];

    const data = type === "pest" ? pestChartsData : diseaseChartsData;

    Object.keys(data[0] ?? {}).forEach((d: any) => {
      if (d !== "timestamp" && !d.endsWith("_riskLevel")) {
        def.push({
          headerName: d,
          field: d,
          autoHeight: true,
          sortable: true,
          filter: "agTextColumnFilter",
          minWidth: 230,
          valueFormatter: (params: any) => {
            const riskLevel = params?.data[`${d}` + `_riskLevel`];
            return `${Number(params?.value).toFixed(2)} (${riskLevel})`;
          },
        });
      }
    });
    return def;
  };

  return (
    <Grid>
      {/* modal */}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box bgcolor={"white"} p={3} width={700}>
          <>
            <Grid container justifyContent={"space-evenly"}>
              <div>
                <Typography>From Date</Typography>
                <DatePicker
                  {...defaultDatePickerPlaceholder}
                  onChange={setModalFromDate}
                  value={modalFromDate}
                />
              </div>
              <div>
                <Typography>To Date</Typography>
                <DatePicker
                  {...defaultDatePickerPlaceholder}
                  onChange={setModalToDate}
                  value={modalToDate}
                />
              </div>
            </Grid>
            <Grid container justifyContent={"space-evenly"} sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                onClick={isDownloadModal ? downloadChartData : loadModalDate}
              >
                {" "}
                {isDownloadModal ? "Doownload" : "Load"} Data
              </Button>
              <Button variant="contained" onClick={handleModalClose}>
                {" "}
                Cancel{" "}
              </Button>
            </Grid>
          </>
        </Box>
      </Modal>

      <Grid container justifyContent={"space-around"} pb={4}>
        <ButtonGroup color="success">
          <Button
            variant={daysCount === 1 ? "contained" : "outlined"}
            onClick={() => handleFromDate(1)}
          >
            1 Day
          </Button>
          <Button
            variant={daysCount === 7 ? "contained" : "outlined"}
            onClick={() => handleFromDate(7)}
          >
            1 Week
          </Button>
          <Button
            variant={daysCount === 30 ? "contained" : "outlined"}
            onClick={() => handleFromDate(30)}
          >
            1 Month
          </Button>
          {/* <Button variant={daysCount === 365 ? 'contained' : 'outlined'} onClick={() => handleFromDate(365)}>1 Year</Button> */}
          <Button
            variant={
              ![1, 7, 30, 365].includes(daysCount) && !isDownloadModal
                ? "contained"
                : "outlined"
            }
            onClick={() => {
              setOpenModal(true);
              setDaysCount(4);
            }}
          >
            Date Range
          </Button>
        </ButtonGroup>
        <ButtonGroup color="success" variant="contained">
          <Button onClick={() => setShowMergedCharts(!showMergedCharts)}>
            {" "}
            {showMergedCharts ? "All Charts" : "Merge Charts"}{" "}
          </Button>
          <Button onClick={handleDownloadChartData}>Download</Button>
        </ButtonGroup>
        <Grid
          alignContent={"center"}
          paddingX={2}
          boxShadow={1}
          borderRadius={2}
          justifyContent={"center"}
        >
          <div
            onClick={() => setIsChartView(!isChartView)}
            style={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "space-between",
            }}
          >
            <ShowChartIcon
              style={{
                color: isChartView ? "#0c9611" : "#7d7d7d",
                marginRight: "8px",
              }}
            />
            <GridOnIcon
              style={{ color: !isChartView ? "#0c9611" : "#7d7d7d" }}
            />
          </div>
        </Grid>
        <Dropdown
          style={{ width: "10%" }}
          value={chartTypeDaily ? "daily" : "hourly"}
          onChange={() => setChartTypeDaily(!chartTypeDaily)}
          displayEmpty={true}
          color="success"
          fullWidth={true}
          variant="standard"
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="hourly">Hourly</MenuItem>
        </Dropdown>
        {isChartView && (
          <Dropdown
            style={{ width: "10%" }}
            value={chartDataType}
            onChange={(e) => setChartDataType(e.target.value)}
            displayEmpty={true}
            color="success"
            fullWidth={true}
            variant="standard"
          >
            <MenuItem value="avg">Average</MenuItem>
            <MenuItem value="min">Minimum</MenuItem>
            <MenuItem value="max">Maximum</MenuItem>
          </Dropdown>
        )}
      </Grid>

      <Typography fontSize={15} textAlign={"center"}>
        Data from <span style={{ fontWeight: "600" }}>{print(fromDate)}</span>{" "}
        to <span style={{ fontWeight: "600" }}>{print(toDate)}</span>
      </Typography>

      {showMergedCharts ? (
        <Grid mt={3}>
          <Select
            options={allChartsDataFieldOptions
              .concat(MinDataFieldOptions)
              .concat(MaxDataFieldOptions)}
            onChange={handleFieldSelect}
            isMulti
          />

          <Grid width={"90%"} mx={"auto"}>
            {selectedFieldsToMerge.length > 0 ? (
              <Line
                data={
                  generateGraphData(selectedFieldsToMerge) as ChartData<
                    "line",
                    any,
                    unknown
                  >
                }
                options={{
                  responsive: true,
                  scales: {
                    x: {
                      title: {
                        color: "green",
                        display: true,
                        text: "Timestamp",
                      },
                      ticks: {
                        autoSkip: true,
                        maxTicksLimit: 7,
                        minRotation: 90,
                        maxRotation: 90,
                      },
                    },
                    y: { beginAtZero: true },
                  },
                }}
              />
            ) : (
              <Typography
                variant="h5"
                textAlign={"center"}
                my={3}
                fontWeight={200}
              >
                Please select fields to merge
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item width={"100%"}>
            <Typography variant="h5" textAlign={"center"} my={3}>
              Device Charts
            </Typography>
            {!isChartView ? (
              <Grid>
                <Select
                  options={filteredOptions}
                  onChange={setSelectedOptions}
                  value={selectedOptions}
                  isMulti
                  isClearable={true}
                  isSearchable={true}
                  placeholder={"- select -"}
                  styles={{
                    container: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      width: "95%",
                      marginLeft: "5px",
                      border: "1px solid #ccc",
                      borderRadius: 5,
                      marginBottom: 12,
                    }),
                    control: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      borderTop: state.isFocused ? "1px" : "0px",
                      borderLeft: state.isFocused ? "1px" : "0px",
                      borderRight: state.isFocused ? "1px" : "0px",
                    }),
                    menu: (baseStyles: any, state: any) => ({
                      ...baseStyles,
                      zIndex: 100,
                    }),
                  }}
                />
                <AgGridComponent
                  columnDef={sensorDef}
                  rowData={historicalData?.data}
                />
              </Grid>
            ) : (
              <DisplayCharts
                datafields={dataFields}
                skipFields={["timestamp"]}
                chartData={historicalData?.data}
                isDaily={chartTypeDaily}
              />
            )}
          </Grid>

          {isChartView && (
            <Grid item width={"100%"}>
              <Typography variant="h5" textAlign={"center"} mt={3}>
                Wind Direction
              </Typography>
              <WindDirectionCharts
                datafields={["windSpeed"]}
                chartData={windChartsData}
                chartWidth={"90%"}
              />
              <Typography style={{ fontSize: 18 }} textAlign={"center"}>
                Scroll right to see more
              </Typography>
            </Grid>
          )}

          <Grid item width={"100%"}>
            <Typography variant="h5" textAlign={"center"} mt={3}>
              Disease Charts
            </Typography>
            {!isChartView ? (
              <AgGridComponent
                columnDef={diseasePestDef("disease")}
                rowData={diseaseChartsData}
              />
            ) : (
              <DisplayCharts
                datafields={diseaseChartsFields}
                chartData={diseaseChartsData}
                isPestDisease={true}
              />
            )}
          </Grid>

          <Grid item width={"100%"}>
            <Typography variant="h5" textAlign={"center"} mt={3}>
              Pest Charts
            </Typography>
            {!isChartView ? (
              <AgGridComponent
                columnDef={diseasePestDef("pest")}
                rowData={pestChartsData}
              />
            ) : (
              <DisplayCharts
                datafields={pestChartsFields}
                chartData={pestChartsData}
                isPestDisease={true}
              />
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default HistoricalData;
